<template>
  <section v-if="cities.length > 0" class="world-map">
    <BaseTitleSection :title="$t('titles.world_map')" class="world-map__title" />
    <HomeWorldMapSvgContainer />
    <HomeWordMapBadges :cities="cities" />
  </section>
</template>

<script>
import HomeWorldMapSvgContainer from './world-map-svg-container'
import HomeWordMapBadges from './word-map-badges.vue'

export default {
  name: 'HomeWorldMap',
  components: { HomeWordMapBadges, HomeWorldMapSvgContainer },
  props: {
    cities: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.world-map {
  @apply container;
  &__title {
    @apply mb-8;
  }
}
</style>
