import { render, staticRenderFns } from "./world-map.vue?vue&type=template&id=2f774b14&scoped=true&"
import script from "./world-map.vue?vue&type=script&lang=js&"
export * from "./world-map.vue?vue&type=script&lang=js&"
import style0 from "./world-map.vue?vue&type=style&index=0&id=2f774b14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f774b14",
  null
  
)

export default component.exports