<template>
  <div class="world-map-badges">
    <BaseBadge
      v-for="(city, i) in cities"
      :key="i"
      class="cursor-pointer"
      :to="city.link"
      tag="a"
    >
      {{ city.name }}
    </BaseBadge>
  </div>
</template>

<script>
export default {
  name: 'HomeWordMapBadges',
  props: {
    cities: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.world-map-badges {
  @apply flex flex-wrap lg:space-x-3 space-x-2 justify-center items-center;
  > a {
    @apply lg:mb-6 mb-2;
  }
}
</style>
