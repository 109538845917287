<template>
  <img
    data-src="@images/public/home/world-map/map.png"
    alt="world map"
    width="814.9"
    height="410.97"
    class="lazyload world-map-png"
  >
</template>

<script>
export default {
  name: 'HomeWorldMapSvgContainer'
}
</script>

<style lang="scss" scoped>
.world-map-png {
  @apply lg:max-w-[814.9px] max-w-[327.25px] h-auto mx-auto lg:mb-14 mb-3;
}
</style>
